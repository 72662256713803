import React, { useCallback } from 'react'
import { ListItemText, Typography, useMediaQuery } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useNavigate } from 'react-router-dom'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import Span from '@jumbo/shared/Span'
import { getBranchById } from 'app/redux/store/features/branches'
import { useDispatch } from 'react-redux'

const RightInfoPanelItem = ({ item }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    const handleFutureExpiryListClick = useCallback(
        ({ item }) => {
            navigate(`/settings/companies/${item.fkCompanyId}/branches/edit/${item.branchId}`, {
                state: { branchId: item.branchId, companyId: item.fkCompanyId },
            })
        },
        [navigate]
    )

    return (
        <ListItemButton
            key={item.id}
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleFutureExpiryListClick({ item })}>
            <ProductionQuantityLimitsIcon />
            <ListItemText
                sx={{ flexBasis: '30%' }}
                primary={
                    <Typography variant={'body1'} mb={0.5} sx={{ marginRight: '10px' }}>
                        <Span
                            sx={{
                                color: 'text.primary',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                mr: '1',
                            }}>
                            {item.companyName}
                        </Span>{' '}
                        <br />
                        <Span
                            sx={{
                                color: 'text.primary',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                mr: '3px !important',
                            }}>
                            {item.branchName}
                        </Span>{' '}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13, marginRight: 30 }} color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.phoneNo}
                        </Span>{' '}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.address}
                        </Span>{' '}
                    </Typography>
                }
            />
            {!isMobile && (
                <>
                    <ListItemText
                        primary={
                            <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                                <Span
                                    sx={{
                                        color: 'text.primary',
                                        mr: 1,
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                    }}>
                                    {formatDateTime(item?.licenseStartDate)}
                                </Span>{' '}
                            </Typography>
                        }
                        sx={{
                            flexBasis: '30%',
                            marginRight: 1,
                        }}
                    />
                    <ListItemText
                        primary={
                            <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                                <Span
                                    sx={{
                                        color: 'text.primary',
                                        mr: 1,
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                    }}>
                                    {formatDateTime(item?.licenseEndDate)}
                                </Span>
                            </Typography>
                        }
                        sx={{
                            flexBasis: '30%',
                            marginRight: 1,
                        }}
                    />
                </>
            )}
        </ListItemButton>
    )
}

export default RightInfoPanelItem

import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const OnlineTransactionsData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { transactions, loading, error } = useSelector((state) => state.transactions)
    const dateColumns = ['txnStartDate', 'txnEndDate']
    const [sorting, setSorting] = useState([{ columnName: 'customerFirstName', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const customercellRender = (data) => {
        return data.customerFirstName + ' ' + data.customerLastName
    }

    const columns = [
        { name: 'txnNumber', title: 'Txn No' },
        { name: 'txnStartDate', title: 'Txn Date' },
        { name: 'customerFirstName', title: 'Customer', getCellValue: customercellRender },
        { name: 'discount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discount) },
        { name: 'txnTotal', title: 'Txn Total', getCellValue: (row) => formatCurrency(row.txnTotal) },
        { name: 'totalPayment', title: 'Total Payment', getCellValue: (row) => formatCurrency(row.totalPayment) },
        { name: 'txnStatus', title: 'Txn Status' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'txnNumber', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'txnStartDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            {
                columnName: 'customerFirstName',
                wordWrapEnabled: true,
                width: min ? '200px' : '20%',
                minWidth: '200px',
            },
            { columnName: 'discount', align: 'right', width: min ? '110px' : '11%', minWidth: '110px' },
            {
                columnName: 'txnTotal',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '100px' : '10%',
                minWidth: '100px',
            },
            {
                columnName: 'totalPayment',
                width: min ? '110px' : '11%',
                minWidth: '110px',
                align: 'right',
                wordWrapEnabled: true,
            },
            { columnName: 'txnStatus', wordWrapEnabled: true, width: min ? '180px' : '18%', minWidth: '180px' },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '9%', minWidth: '90px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={transactions.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={transactions.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default OnlineTransactionsData

import React from 'react'
import { Grid, Typography } from '@mui/material'
import { ResponsiveContainer } from 'recharts'
import CardIconText from '@jumbo/shared/CardIconText'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import EuroIcon from '@mui/icons-material/Euro'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'

const HeaderCardList = ({ item }) => {
    const getCardStyle = (icon) => {
        switch (icon) {
            case 'company':
                return { color: 'error.dark', iconComponent: <AddBusinessIcon fontSize='large' /> }
            case 'epos':
                return {
                    color: '#4CAF50',
                    iconComponent: <EuroIcon fontSize='large' />,
                } // Green
            case 'spiral':
                return { color: '#2196F3', iconComponent: <CurrencyExchangeIcon fontSize='large' /> }
            case 'expired':
                return { color: '#FF9800', iconComponent: <LibraryBooksIcon fontSize='large' /> }
            default:
                return { color: '#9C27B0', iconComponent: <AddBusinessIcon fontSize='large' /> }
        }
    }

    return (
        <CardIconText
            icon={getCardStyle(item.icon).iconComponent}
            color={getCardStyle(item.icon).color}
            variant='contained'
            sx={{ mt: 1 }}
            title={
                <Grid sx={{ mt: 2, ml: -2.5, pl: 0.6 }}>
                    <Typography variant='h3' fontFamily='Lobster, cursive' color={getCardStyle(item.icon).color}>
                        {item.title}
                    </Typography>
                </Grid>
            }
            subTitle={
                <React.Fragment>
                    <Grid sx={{ ml: '52%', mt: -10.5 }}>
                        <Typography
                            variant='h5'
                            sx={{
                                ml: 7,
                                mt: 8,
                                fontSize: '1.2rem',
                                mr: '3%',
                                alignSelf: 'self-start',
                            }}
                            color={getCardStyle(item.icon).color}>
                            {item.totalValue}
                        </Typography>
                    </Grid>
                </React.Fragment>
            }
        />
    )
}

export default HeaderCardList

import React from 'react'
import JumboLayout from '@jumbo/components/JumboLayout'
import Header from '../shared/headers/Header'
import Sidebar from '../shared/sidebars/Sidebar'
import useJumboLayout from '@jumbo/hooks/useJumboLayout'
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme'
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { headerTheme as defaultTheme } from '../../themes/header/default'
import layoutConfig from './layoutConfig'

const VerticalDefault = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout()
    const { setHeaderTheme } = useJumboHeaderTheme()
    const { theme } = useJumboTheme()
    const { sidebarOptions } = useJumboLayoutSidebar()

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
    }, [])

    React.useEffect(() => {
        setHeaderTheme({ ...theme, ...defaultTheme })
    }, [sidebarOptions.style])

    return (
        <JumboLayout
            header={<Header />}
            sidebar={<Sidebar />}
            headerSx={{
                height: 80,
            }}>
            {children}
        </JumboLayout>
    )
}

export default VerticalDefault

import React, { useState } from 'react'
import { List, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import RightInfoPanelItem from './RightInfoPanelItem'
import Div from '@jumbo/shared/Div'
import Chip from '@mui/material/Chip'
import { useSelector } from 'react-redux'
import { Pagination } from '@mui/material'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import LoaderOverlay from 'app/utils/components/LoaderOverlay'
import LoaderOverlayDashboard from 'app/utils/components/LoaderOverlayDashboard'

const RightInfoPanelList = ({ scrollHeight, OnPageChangeTransaction }) => {
    const pageSize = 2
    const { sale, loading } = useSelector((state) => state.dashboard)
    const { theme } = useJumboTheme()
    const [currentPage, setCurrentPage] = useState(1)

    const onCurrentPageChange = async (page) => {
        try {
            await OnPageChangeTransaction.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    return (
        <JumboCardQuick
            title={
                <Div
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        },
                    }}>
                    <Typography
                        variant='h3'
                        mb={0}
                        fontFamily='Lobster, cursive'
                        color='primary.dark'
                        sx={{
                            minWidth: 245,
                            [theme.breakpoints.down('md')]: {
                                minWidth: '100%',
                                marginBottom: 2,
                            },
                        }}>
                        <LoaderOverlayDashboard loading={loading} />
                        Transaction List
                    </Typography>
                </Div>
            }
            action={<Chip label={'Last ' + 3 + ' months'} color={'info'} size={'small'} />}
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -0.75,
                },
            }}
            wrapperSx={{
                p: 0,
                '&:last-child': {
                    pb: 2,
                },
                '& .MuiCollapse-entered:last-child': {
                    '& .MuiListItemButton-root': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                },
            }}>
            <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight || 585}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}>
                <List disablePadding>
                    {sale.transactionList.data.map((item, index) => {
                        return <RightInfoPanelItem item={item} key={index} />
                    })}
                </List>
                <Pagination
                    count={Math.ceil(sale.transactionList.totalCount / pageSize)}
                    onChange={(event, page) => onCurrentPageChange(page)}
                    page={currentPage}
                    siblingCount={1}
                    boundaryCount={1}
                    size='small'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                        marginRight: '20px',
                        '& .MuiPaginationItem-root': { margin: '0 6px' },
                        '& .Mui-selected': { backgroundColor: 'black', color: 'white' },
                    }}
                />
            </JumboScrollbar>
        </JumboCardQuick>
    )
}

export default RightInfoPanelList

import React, { useCallback } from 'react'
import { ListItemAvatar, ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import Chip from '@mui/material/Chip'
import { useNavigate } from 'react-router-dom'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'

import { useDispatch } from 'react-redux'
import Span from '@jumbo/shared/Span'
import { getTransactionById } from 'app/redux/store/features/transactions'

const RightInfoPanelItem = ({ item }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleTxnNumberClick = useCallback(
        ({ item }) => {
            dispatch(getTransactionById({ posTxId: item.posTxId, onlineTxn: false }))
            navigate(`/store/sales/all-transaction/details/${item.posTxId}`, { state: { posTxId: item.posTxId } })
        },
        [dispatch, navigate]
    )

    return (
        <ListItemButton
            key={item.id}
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleTxnNumberClick({ item })}>
            <MonetizationOnIcon sx={{ mr: 2 }} />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='h5' mb={0.5} color='text.primary'>
                        {item.txnNumber}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='h6' mb={0.5} color='text.primary'>
                        <Span sx={{ mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.customerFirstName + ' ' + item.customerLastName}
                        </Span>{' '}
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        <Span sx={{ mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {formatDateTime(item.txnStartDate)}
                        </Span>{' '}
                    </Typography>
                }
                sx={{
                    flexBasis: '30%',
                    marginRight: 3,
                }}
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        <Span>{formatCurrency(item.txnTotal)}</Span>{' '}
                    </Typography>
                }
                secondary={'Total'}
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
            />
            <ListItemText
                sx={{ alignSelf: 'self-start', marginRight: 1 }}
                primary={
                    <Typography variant='body1' component={'span'} color='text.primary'>
                        {formatCurrency(item.totalPayment)}
                    </Typography>
                }
                secondary={'Overdue'}
            />
        </ListItemButton>
    )
}

export default RightInfoPanelItem

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import Typography from '@mui/material/Typography'
import { TabContext, TabPanel } from '@mui/lab'
import BranchConfig from './components/branchConfig/BranchConfig'
import AutoNumbers from './components/autoNumbers/AutoNumbers'
import ReportConfig from './components/reportConfig/ReportConfig'
import SyncConfig from './components/syncConfig/syncConfig'
import DiscountsGrid from './components/discounts/DiscountsGrid'

function BranchSettings() {
    const navigate = useNavigate()
    const { tabTitle } = useParams()
    const [value, setValue] = React.useState(tabTitle ? tabTitle : 'syncconfig')

    const onTabSelectionChanged = (e, newValue) => {
        setValue(newValue)
        navigate(`/store/settings/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} sx={{ mt: 1 }} variant={'h4'}>
                        Branch Settings
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={value}>
                    <TabList sx={{ bgcolor: 'background.default' }} onChange={onTabSelectionChanged}>
                        <Tab
                            label={'Sync Config'}
                            value={'syncconfig'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                        <Tab
                            label={'Branch Config'}
                            value={'branchconfig'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                        <Tab
                            label={'Report Config'}
                            value={'reportconfig'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                        <Tab
                            label={'Auto Numbers'}
                            value={'autonumbers'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                        <Tab
                            label={'Discounts'}
                            value={'discounts'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                    </TabList>
                    <TabPanel value='syncconfig'>
                        <SyncConfig />
                    </TabPanel>
                    <TabPanel value='branchconfig'>
                        <BranchConfig />
                    </TabPanel>
                    <TabPanel value='reportconfig'>
                        <ReportConfig />
                    </TabPanel>
                    <TabPanel value='autonumbers'>
                        <AutoNumbers />
                    </TabPanel>
                    <TabPanel value='discounts'>
                        <DiscountsGrid />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default BranchSettings

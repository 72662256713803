import React, { useCallback } from 'react'
import { ListItemText, Typography, useMediaQuery } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useNavigate } from 'react-router-dom'
import Span from '@jumbo/shared/Span'
import { getBranchById } from 'app/redux/store/features/branches'
import { useDispatch } from 'react-redux'

const LeftInfoPanelItem = ({ item }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    const handleRecentRenewedListClick = useCallback(
        ({ item }) => {
            dispatch(getBranchById({ branchId: item.branchId, companyId: item.fkCompanyId }))
            navigate(`/settings/companies/${item.fkCompanyId}/branches/edit/${item.branchId}`, {
                state: { branchId: item.branchId, companyId: item.fkCompanyId },
            })
        },
        [navigate]
    )

    return (
        <ListItemButton
            component='li'
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleRecentRenewedListClick({ item })}>
            <ListItemText
                sx={{ flexBasis: '30%' }}
                primary={
                    <Typography variant='h5' mb={0.5} color='text.primary' mr={1}>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.companyName}
                        </Span>{' '}
                        <br />
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.branchName}
                        </Span>{' '}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.phoneNo}
                        </Span>{' '}
                    </Typography>
                }
            />
            <ListItemText
                sx={{
                    flexBasis: '30%',
                    marginRight: 1,
                }}
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.address}
                        </Span>{' '}
                    </Typography>
                }
            />
            {!isMobile && (
                <>
                    <ListItemText
                        sx={{
                            flexBasis: '30%',
                            marginRight: 1,
                        }}
                        primary={
                            <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                                <Span
                                    sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    {formatDateTime(item.licenseStartDate)}
                                </Span>{' '}
                            </Typography>
                        }
                    />
                    <ListItemText
                        sx={{
                            flexBasis: '30%',
                            marginRight: 1,
                        }}
                        primary={
                            <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                                <Span
                                    sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    {formatDateTime(item?.licenseEndDate)}
                                </Span>{' '}
                            </Typography>
                        }
                    />
                </>
            )}
        </ListItemButton>
    )
}

export default LeftInfoPanelItem

import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { Grid, Table, TableHeaderRow, PagingPanel, TableSelection } from '@devexpress/dx-react-grid-material-ui'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { getSupplierById, deleteSupplier } from 'app/redux/store/features/suppliers.js'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useTheme from '@mui/system/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

const SupplierGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { suppliers, loading, error } = useSelector((state) => state.suppliers)
    const booleanColumns = ['isTaxExempted']
    const [sorting, setSorting] = useState([{ columnName: 'supplierCode', direction: 'asc' }])
    const pageSize = 25
    const theme = useTheme()
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const isIpadScreen = useMediaQuery('(min-width:768px) and (max-width:1024px)')

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getSupplierById({ supplierId: id }))
        navigate(`/store/shipments/supplier/edit/${id}`, { state: { supplierId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.supplierId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.supplierId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveSupplier(row.supplierId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.supplierId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.supplierCode}
            </Link>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>

    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const hideDialogAndRefreshSupplierList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Supplier removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveSupplier = async (supplierId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteSupplier({ supplierId: supplierId }))
                hideDialogAndRefreshSupplierList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'supplierCode', title: 'Supplier Code', getCellValue: renderLinkCell },
        { name: 'barcode', title: 'Bar Code' },
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'companyName', title: 'Company Name' },
        { name: 'isTaxExempted', title: 'Tax Exempted' },
        { name: 'balanceAmount', title: 'Balance Amount', getCellValue: (row) => formatCurrency(row.balanceAmount) },
        { name: 'supplierName', title: 'Supplier Name', hidden: true },
        { name: 'supplierCompanyName', title: 'Supplier Company Name', hidden: true },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const visibleColumns = columns.filter((column) => !column.hidden)

    const columnWidths = [
        { columnName: 'supplierCode', width: isSmScreen || isIpadScreen ? '160px' : '160px', wordWrapEnabled: true },
        { columnName: 'barcode', width: isSmScreen || isIpadScreen ? '130px' : '160px' },
        { columnName: 'firstName', width: isSmScreen || isIpadScreen ? '160px' : '200px', wordWrapEnabled: true },
        { columnName: 'lastName', width: isSmScreen || isIpadScreen ? '160px' : '200px', wordWrapEnabled: true },
        { columnName: 'companyName', width: isSmScreen || isIpadScreen ? '200px' : '388px', wordWrapEnabled: true },
        { columnName: 'isTaxExempted', align: 'center', width: isSmScreen || isIpadScreen ? '140px' : '140px' },
        { columnName: 'balanceAmount', align: 'right', width: isSmScreen || isIpadScreen ? '160px' : '160px' },
        { columnName: 'Action', align: 'center', width: isSmScreen || isIpadScreen ? '90px' : '120px' },
    ]

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={suppliers.data} columns={visibleColumns}>
                    <BooleanTypeProvider for={booleanColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={suppliers.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default SupplierGridData

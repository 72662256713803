import React from 'react'
import { Grid, Typography } from '@mui/material'
import CardIconText from '@jumbo/shared/CardIconText'
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone'
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone'
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { formattedDate } from 'app/utils/components/FormatDateTime/FormatDateTime'

const HeaderCardList = ({ item }) => {
    const getCardStyle = (icon) => {
        switch (icon) {
            case 'sales':
                return { color: '#00A073', iconComponent: <PointOfSaleTwoToneIcon /> }
            case 'payment':
                return { color: '#DF295E', iconComponent: <LocalAtmTwoToneIcon /> }
            case 'cash':
                return { color: '#008598', iconComponent: <MonetizationOnTwoToneIcon /> }
            case 'credit':
                return { color: '#BB6900', iconComponent: <CreditCardTwoToneIcon /> }
            default:
                return { color: '#BB6901', iconComponent: <PointOfSaleTwoToneIcon /> }
        }
    }

    return (
        <CardIconText
            icon={getCardStyle(item.icon).iconComponent}
            color={getCardStyle(item.icon).color}
            variant='contained'
            sx={{ mt: 1 }}
            title={
                <Grid sx={{ mt: 2, ml: -2.5, pl: 0.6 }}>
                    <Typography variant='h3' fontFamily='Lobster, cursive' color={getCardStyle(item.icon).color}>
                        {item.title}
                    </Typography>
                </Grid>
            }
            subTitle={
                <React.Fragment>
                    <Typography variant='h5' sx={{ pt: 0.5, ml: -2 }}>
                        {formattedDate(item.from)}
                    </Typography>
                    <Grid sx={{ ml: 12, mt: -10.5 }}>
                        <Typography
                            variant='h5'
                            sx={{ ml: 7, mt: 8, fontSize: '1rem' }}
                            color={getCardStyle(item.icon).color}>
                            {formatCurrency(item.totalValue)}
                        </Typography>
                    </Grid>
                </React.Fragment>
            }
        />
    )
}

export default HeaderCardList

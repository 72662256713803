import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardSales, getDashboardShipments, getDashboardTxn } from 'app/redux/store/features/dashboard'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import HeaderCard from './user/headerbar/HeaderCard'
import BannerLeft from './user/bannerbar/BannerLeft'
import BannerRight from './user/bannerbar/BannerRight'
import LeftInfoPanel from './user/infoSection/leftInfoPanel/LeftInfoPanel'
import RightInfoPanel from './user/infoSection/rightInfoPanel/RightInfoPanel'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import LoaderOverlay from 'app/utils/components/LoaderOverlay'

const UserDashboard = () => {
    const { authUser } = useJumboAuth()
    const pageSize = 10
    const dispatch = useDispatch()
    const { loading, error } = useSelector((state) => state.dashboard)
    const [filter, setFilter] = useState({
        monthStartDate: DateUtils.getStartDate(90).toISOString(),
        monthEndDate: DateUtils.getEndDate(0).toISOString(),
    })
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(async () => {
        setApiCallInProgress(true)
        dispatch(getDashboardSales({ ...filter, skip: 0, take: 10 }))
    }, [dispatch])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const OnPageChangeShipment = useMutation({
        mutationFn: async (page) => {
            const currentFilter = { ...filter, skip: pageSize * (page - 1), take: pageSize }
            setFilter(currentFilter)
            dispatch(getDashboardShipments(currentFilter))
        },
    })

    const OnPageChangeTransaction = useMutation({
        mutationFn: async (page) => {
            const currentFilter = { ...filter, skip: pageSize * (page - 1), take: pageSize }
            setFilter(currentFilter)
            dispatch(getDashboardTxn(currentFilter))
        },
    })

    return (
        <Grid container spacing={3.75}>
            <LoaderOverlay loading={loading} />
            <>
                <Grid item xs={12} sm={12} lg={12}>
                    <HeaderCard />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <BannerLeft />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <BannerRight />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <LeftInfoPanel OnPageChangeShipment={OnPageChangeShipment} />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <RightInfoPanel OnPageChangeTransaction={OnPageChangeTransaction} />
                </Grid>
            </>
        </Grid>
    )
}

export default UserDashboard

import React from 'react'
import Div from '@jumbo/shared/Div'
import useReportApp from '../../../hooks/useReportApp'
import DailySaleReport from '../../epos/SalesReportList/components/dailysalereport'
import DailySaleSummaryReport from '../../epos/SalesReportList/components/dailysalesummaryreport/DailySaleSummaryReport'
import SaleProfitLossReport from '../../epos/SalesReportList/components/saleprofitlossreport/SaleProfitLossReport'
import SupplierAccountReport from '../../epos/OrderReportList/components/supplieraccountreport/SupplierAccountReport'
import SaleDetailReport from '../../epos/SalesReportList/components/saledetailreport/SaleDetailReport'
import SaleDetailReportSpiral from '../../spiral/SalesReportList/components/saleDetailReportSpiral/SaleDetailReportSpiral'
import DailySaleSummaryReportSpiral from '../../spiral/SalesReportList/components/dailySaleSummaryReportSpiral/DailySaleSummaryReportSpiral'
import DailySaleReportSpiral from '../../spiral/SalesReportList/components/dailysalereportSpiral/DailySaleReportSpiral'
import OrderListReport from '../../epos/OrderReportList/components/orderlistreport/OrderListReport'
import CashRegisterReport from '../../epos/CashReportList/components/cashregisterreport/CashRegisterReport'
import InventoryLoadFormReport from '../../epos/InventoryReportList/components/inventoryloadform/InventoryLoadFormReport'
import InventoryListReport from '../../epos/InventoryReportList/components/inventorylistreport/InventoryListReport'
import CustomerReceivableReport from '../../epos/SalesReportList/components/customerreceivablereport/CustomerReceivableReport'
import CustomerReceivableDetailReport from '../../epos/SalesReportList/components/customerreceivabledetailreport/CustomerReceivableDetailReport'
import DailyCashDetailReport from '../../epos/CashReportList/components/dailycashdetailreport/DailyCashDetailReport'

const ActiveReportView = () => {
    const { activeReport } = useReportApp()
    const salesReportList = {
        dailysalessummaryreportepos: DailySaleSummaryReport,
        dailysalesreportepos: DailySaleReport,
        customerreceivablereportepos: CustomerReceivableReport,
        customerreceivabledetailreportepos: CustomerReceivableDetailReport,
        saleprofitlossreportepos: SaleProfitLossReport,
        saledetailreportepos: SaleDetailReport,
        dailysalessummaryreportspiral: DailySaleSummaryReportSpiral,
        dailysalesreportspiral: DailySaleReportSpiral,
        saledetailreportspiral: SaleDetailReportSpiral,
    }

    const orderReportList = {
        orderlistreportepos: OrderListReport,
        supplieraccountreportepos: SupplierAccountReport,
    }

    const inventoryReportList = {
        inventorylistreportepos: InventoryListReport,
        inventoryloadformreportepos: InventoryLoadFormReport,
    }

    const cashReportList = {
        dailycashdetailreportepos: DailyCashDetailReport,
        cashregisterreportepos: CashRegisterReport,
    }

    if (activeReport?.category === 'sales') {
        return <Div>{React.createElement(salesReportList[activeReport.id.replace(/-/g, '')])}</Div>
    } else if (activeReport?.category === 'order') {
        return <Div>{React.createElement(orderReportList[activeReport.id.replace(/-/g, '')])}</Div>
    } else if (activeReport?.category === 'inventory') {
        return <Div>{React.createElement(inventoryReportList[activeReport.id.replace(/-/g, '')])}</Div>
    } else if (activeReport?.category === 'cash') {
        return <Div>{React.createElement(cashReportList[activeReport.id.replace(/-/g, '')])}</Div>
    } else {
        return <Div />
    }
}

export default ActiveReportView
